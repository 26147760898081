import classnames from 'classnames';
import React, { Component, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

export type DatepickerInputProps = {
    onClick?: () => void,
    value?: string,
    placeholderText?: string,
    isValid?: boolean,
    isInvalid?: boolean,
    isRequired?: boolean,
    id?: string,
};

class DatepickerInput extends Component<DatepickerInputProps> {
    
    onDateValueChange = () => {
        console.log('date value changed');
    };
    
    render() {
        return (
            <input
                type="text"
                className={classnames("form-control date", {
                    'is-valid': this.props.isValid,
                    'is-invalid': this.props.isInvalid,
                })}
                id={this.props.id}
                onClick={this.props.onClick}
                value={this.props.value}
                onChange={this.onDateValueChange}
                placeholder={this.props.placeholderText}
                required={this.props.isRequired}/>
        );
    }
}

class DatepickerInputWithAddon extends Component<DatepickerInputProps> {
    
    render() {
        return (
            <div className="input-group">
                <input
                    type="text"
                    className={classnames("form-control date", {
                        'is-valid': this.props.isValid,
                        'is-invalid': this.props.isInvalid,
                    })}
                    id={this.props.id}
                    onClick={this.props.onClick}
                    value={this.props.value}
                    placeholder={this.props.placeholderText}
                    required={this.props.isRequired}
                    readOnly/>
                <div className="input-group-append">
                    <span className="input-group-text bg-primary border-primary text-white">
                        <i className="mdi mdi-calendar-range font-13"></i>
                    </span>
                </div>
            </div>
        );
    }
}

export type HyperDatepickerProps =  ReactDatePickerProps & {
    hideAddon?: boolean,
    isValid?: boolean,
    isInvalid?: boolean,
};

const HyperDatepicker = (props: HyperDatepickerProps) => {
    const {
        hideAddon,
        isValid = false,
        isInvalid = false,
        required,
        dateFormat,
        placeholderText,
        id,
        ...datePickerProps
    } = props;

    const inputProps: DatepickerInputProps = {
        placeholderText,
        isValid,
        isInvalid,
        isRequired: required,
        id,
    };
    
    const [startDate, setStartDate] = useState(new Date());
    const input = (hideAddon || false) === true ? <DatepickerInput {...inputProps}/> : <DatepickerInputWithAddon {...inputProps}/>;
    const dateFormatWithDefault = dateFormat === undefined ? "dd/MM/yyyy" : dateFormat

    return (
        <DatePicker
            dateFormat={dateFormatWithDefault}
            customInput={input}
            selected={startDate}
            onChange={setStartDate}
            {...datePickerProps}/>
    );
}

export default HyperDatepicker;
