import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { FormGroup, Label, Table } from 'reactstrap';
import { CylinderSize } from '../../../../../models/rewards/calor/Claim';
import { CylinderPurchaseDetails } from '../../../../../models/rewards/calor/ClaimForm';
import VatPrice from '../../../../../models/VatPrice';
import { CylinderQuantityInput, IncludesVatCheckbox, PerCylinderPriceInput } from './_inputs';
import { calculateTotals, DisplayTotal } from './_totals';

export const purchaseDetailsPropertyName = (size: CylinderSize): string => `purchaseDetails${size}`;

const PurchaseDetailsGroup = () => {
    const {watch, formState} = useFormContext();
    const totals = calculateTotals(
        watch('purchaseDetails13kg') as CylinderPurchaseDetails,
        watch('purchaseDetails19kg') as CylinderPurchaseDetails,
        watch('purchaseDetails47kg') as CylinderPurchaseDetails);

    const totalQuantityInvalid = formState.isSubmitted && totals.quantity < 1;

    return <>
        <Label className='mb-0' for='claim-details'>Receipt details:</Label>
        <p>One claim per receipt and receipt must reflect the claim.</p>

        <FormGroup id='claim-details'>
            <Table responsive className='text-center'>
                <thead>
                    <tr>
                        <th className='align-middle border-top-0'>Size</th>
                        <th className='align-middle border-top-0'>Quantity</th>
                        <th className='align-middle border-top-0'>Price per Cylinder</th>
                        <th className='align-middle border-top-0'>VAT</th>
                        <th className='align-middle border-top-0'>Total inc. VAT</th>
                        <th className='align-middle border-top-0'>Total exc. VAT</th>
                    </tr>
                </thead>

                <tbody>
                    {['13kg', '19kg', '47kg'].map((size: CylinderSize) => {
                        const rowTotals = totals[`totals${size}`] as VatPrice;

                        return (
                            <tr key={`row-${size}`}>
                                <td className='align-middle'>{size}</td>
                                <td className='align-middle'><CylinderQuantityInput size={size}/></td>
                                <td className='align-middle'><PerCylinderPriceInput size={size}/></td>
                                <td className='align-middle'><IncludesVatCheckbox size={size}/></td>
                                <td className='align-middle'><DisplayTotal total={rowTotals.incVat}/></td>
                                <td className='align-middle'><DisplayTotal total={rowTotals.excVat}/></td>
                            </tr>
                        );
                    })}

                    <tr>
                        <th className='align-middle'>Total</th>
                        <th className={classnames('align-middle', {'text-danger': totalQuantityInvalid})}>{totals.quantity}</th>
                        <th></th>
                        <th></th>
                        <th className='align-middle'><DisplayTotal total={totals.totalOfTotals.incVat}/></th>
                        <th className='align-middle'><DisplayTotal total={totals.totalOfTotals.excVat}/></th>
                    </tr>
                </tbody>
            </Table>

            {totalQuantityInvalid && <span className='d-block text-danger'>Purchase details required</span>}
        </FormGroup>

        <p>
            <span>Cashback is only paid on exec VAT prices not inc. VAT.</span><br></br>
            <span className='text-uppercase text-primary'>You will be taken off the scheme if you submit false claims.</span>
        </p>
    </>
}

export default PurchaseDetailsGroup;