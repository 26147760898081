import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useNcassAuth0 } from "../../hooks/useNcassAuth0";
import LoadingPlaceholder from "../LoadingPlaceholder";
import { logoutActions } from "./actions";
import settings from "../../config";
import { LogoutOptions } from '@auth0/auth0-react';

const runLogoutActions = () => logoutActions.forEach(action => action());

export const logOutWithAuth0 = (logout: (options: LogoutOptions) => void, remoteLogout: boolean) => {

        const runLogoutActions = () => logoutActions.forEach(action => action());

        const returnTo = remoteLogout ? `${settings.REMOTE_LOGOUT_URL}` : `${settings.AUTH0.LOGOUT_URL}`;
        
        logout({
          returnTo: returnTo,
        });
        runLogoutActions();
}

const Logout = () => {
    const { logout } = useNcassAuth0();
    const [searchParams] = useSearchParams();
    const remote = searchParams.get('remote');

    const returnTo = remote === 'true' ? `${settings.REMOTE_LOGOUT_URL}` : `${settings.AUTH0.LOGOUT_URL}`
  
    useEffect(() => {
      logout({
        returnTo: returnTo,
      });

      runLogoutActions();
    }, [logout, returnTo]);

    return <LoadingPlaceholder/>;
};

export default Logout;
