import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { Col, Form, Row } from 'reactstrap';
import FormDataAddon from 'wretch/addons/formData';
import { WretchError } from 'wretch/types';
import PageTitle from '../../../../../components/common/PageTitle';
import { removeLocalStorageValue } from '../../../../../components/Logout/helpers';
import OneTimeModal from '../../../../../components/modals/OneTimeModal';
import ClaimFormCard from '../../../../../components/rewards/calor/claim-form/ClaimFormCard';
import DeclarationAndSubmissionGroup from '../../../../../components/rewards/calor/claim-form/DeclarationAndSubmissionGroup';
import PurchaseDateGroup from '../../../../../components/rewards/calor/claim-form/PurchaseDateGroup';
import PurchaseDetailsGroup from '../../../../../components/rewards/calor/claim-form/PurchaseDetailsGroup';
import ReceiptNumberGroup from '../../../../../components/rewards/calor/claim-form/ReceiptNumberGroup';
import ReceiptSelectionGroup from '../../../../../components/rewards/calor/claim-form/ReceiptSelectionGroup';
import RetailerDetailsGroup from '../../../../../components/rewards/calor/claim-form/RetailerDetailsGroup';
import VatDetailsGroup from '../../../../../components/rewards/calor/claim-form/VatDetailsGroup';
import MemberSchemesDigestProvider from '../../../../../components/rewards/MemberSchemesDigestProvider';
import { formatDateOnlyISO } from '../../../../../helpers/formatters';
import useApi from '../../../../../hooks/useApi';
import { useMembership } from '../../../../../hooks/useMembership';
import { useResetableState } from '../../../../../hooks/useResetableState';
import Claim, { CylinderPuchaseDetailsWithVat, CylinderSize, CylinderSizeArray } from '../../../../../models/rewards/calor/Claim';
import { ClaimFormState, CylinderPurchaseDetails } from '../../../../../models/rewards/calor/ClaimForm';
import SuccessMessage from './SuccessMessage';
import UnableToMakeClaimMessage from './UnableToMakeClaimMessage';

const localStorageKey = 'calor-claim-form-should-show-attention-message';

export const logoutActions = [
    removeLocalStorageValue(localStorageKey),
]

type ClaimFormSuccessResponse = {
    claimReference: string,
}

const emptyClaimFormState: ClaimFormState = {
    purchaseDate: null,
    purchaseDetails13kg: {
        pricePerCylinder: '0',
        quantity: '0',
        vatIncluded: false,
    },
    purchaseDetails19kg: {
        pricePerCylinder: '0',
        quantity: '0',
        vatIncluded: false,
    },
    purchaseDetails47kg: {
        pricePerCylinder: '0',
        quantity: '0',
        vatIncluded: false,
    },
    receiptFile: null,
    receiptNumber: '',
    vatNumber: '',
    retailer: null,
}

const convertPurchaseDetails = (purchaseDetails: CylinderPurchaseDetails, size: CylinderSize) => {
    return {
        quantity: parseInt(purchaseDetails.quantity),
        pricePerCylinder: parseFloat(purchaseDetails.pricePerCylinder),
        vatIncluded: purchaseDetails.vatIncluded,
        cylinderSize: size,
    }
}

const constructInitialFormState = (claim: Claim): ClaimFormState => {
    if (claim == null) {
        return emptyClaimFormState;
    }

    const {
        purchaseDate,
        receiptNumber,
        vatNumber,
        retailer,
        purchaseDetails,
    } = claim;

    const [
        purchaseDetails13kg,
        purchaseDetails19kg,
        purchaseDetails47kg,
    ] = constructInitialPurchaseDetails(purchaseDetails);

    return {
        ...emptyClaimFormState,
        purchaseDate: new Date(purchaseDate),
        receiptNumber,
        retailer,
        vatNumber,
        purchaseDetails13kg,
        purchaseDetails19kg,
        purchaseDetails47kg,
    };
}

const constructInitialPurchaseDetails = (purchaseDetails: CylinderPuchaseDetailsWithVat[]): CylinderPurchaseDetails[] => {
    const convertPurchaseDetails = (cylinderSize: CylinderSize): CylinderPurchaseDetails => {
        const details: Partial<CylinderPuchaseDetailsWithVat> = purchaseDetails.find(details => details.cylinderSize === cylinderSize) || {};
        return {
            pricePerCylinder: (details.pricePerCylinder || 0).toString(),
            quantity: (details.quantity || 0).toString(),
            vatIncluded: details.vatIncluded === undefined ? false : details.vatIncluded,
        }
    }

    return CylinderSizeArray.map(convertPurchaseDetails);
}

const CalorClaimForm = () => {
    const [successResponse, setSuccessResponse, resetSuccessResponse] = useResetableState<ClaimFormSuccessResponse>(null);
    const [errorMessage, setErrorMessage, resetErrorMessage] = useResetableState<string>(null);
    const [isWorking, setIsWorking] = useState(false);

    const { membershipNumber } = useMembership();
    const { getApi } = useApi();
    const { state } = useLocation();

    const initialFormState = useMemo(() => constructInitialFormState(state as Claim), [state]);

    const methods = useForm<ClaimFormState>({
        defaultValues: initialFormState,
    });

    const handleSubmit = async (state: ClaimFormState): Promise<void> => {
        resetErrorMessage();
        setIsWorking(true);

        const api = await getApi();
        api.url(`/calor/members/${membershipNumber}/upload-claim`)
            .addon(FormDataAddon)
            .formData({
                retailerId: state.retailer.id,
                vatNumber: state.vatNumber,
                purchaseDate: formatDateOnlyISO(state.purchaseDate),
                receiptNumber: state.receiptNumber,
                purchaseDetails: [
                    JSON.stringify(convertPurchaseDetails(state.purchaseDetails13kg, '13kg')),
                    JSON.stringify(convertPurchaseDetails(state.purchaseDetails19kg, '19kg')),
                    JSON.stringify(convertPurchaseDetails(state.purchaseDetails47kg, '47kg')),
                ],
                receiptImage: state.receiptFile,
            })
            .post()
            .unauthorized(() => setErrorMessage('Your account must be active to sign up to the cashback on Calor purchases scheme.'))
            .error(409, () => setErrorMessage('There was an error when submitting the claim, if you have not yet signed up to the scheme please do so, otherwise please contact your account manager if this continues.'))
            .error(422, () => setErrorMessage('Unfortunately the period to which this claim would apply has been closed for claim submissions.'))
            .internalError(() => setErrorMessage('There was an error when submitting the claim, feel free to try again, if this continues please contact your account manager.'))
            .json((response: ClaimFormSuccessResponse) => {
                setSuccessResponse(response);
                return response;
            })
            .catch((err: WretchError) => setErrorMessage(`Unexpected error (status code: ${err.status}) when submitting claim.`))
            .finally(() => setIsWorking(false));
    }

    const handleMakeAnotherClaim = () => {
        resetSuccessResponse();
        methods.reset(emptyClaimFormState);
    }

    return <>
        <PageTitle
            breadCrumbItems={[
                        { label: 'Rewards', path: '/rewards/home' },
                        { label: 'Calor', active: true },
                        { label: 'Claim Form', active: true },
                    ]}
            title='Calor Claim Form'
        />
        <OneTimeModal
            localStorageKey={localStorageKey}
            dontShowAgainKey='calor-claim-form-should-show-attention-message-dont-show-again-v1'
            modalTitle='Calor Claim Information'
            buttonJustification='end'>
            {/** if changing this list please update the version number of the dontShowAgainKey by 1 **/}
            Please note:
            <ul>
                <li><b>If you are a Calor account holder and buy direct, You do not need to submit a claim as purchases are automatically recorded.</b></li>
                <li>You have 3 months from the date of purchase to upload your receipts</li>
                <li>You can only claim once you are signed up to the deal, any Calor purchases before this time can not be claimed for.</li>
                <li>You will need to fill out a claim form for each receipt. Calor and NCASS will then contact you to approve your claim.</li>
                <li>If your claim is rejected you can edit and resubmit your claim by clicking resubmit on your rejected claims.</li>
            </ul>
        </OneTimeModal>

        <Row className='justify-content-center' id='calor-claim-form-content'>
            <Col lg={8}>
                <MemberSchemesDigestProvider membershipNumber={membershipNumber}>
                    {({ calor: { accounts } }) => {
                                if (!accounts.some(account => account.accountStatus === 'Approved'))
                                    return <UnableToMakeClaimMessage />

                                if (successResponse)
                                    return <SuccessMessage
                                        claimReference={successResponse.claimReference}
                                        handleMakeAnotherClaim={handleMakeAnotherClaim} />

                                return (
                                    <FormProvider {...methods}>
                                        <Form onSubmit={methods.handleSubmit(handleSubmit)}>
                                            <ClaimFormCard title='Step 1: Enter Retailer &amp; VAT Details'>
                                                <RetailerDetailsGroup membershipNumber={membershipNumber} />
                                                <VatDetailsGroup />
                                            </ClaimFormCard>

                                            <ClaimFormCard title='Step 2: Enter Purchase Date'>
                                                <PurchaseDateGroup />
                                            </ClaimFormCard>

                                            <ClaimFormCard title='Step 3: Enter Purchase Details'>
                                                <ReceiptNumberGroup />
                                                <PurchaseDetailsGroup />
                                            </ClaimFormCard>

                                            <ClaimFormCard title='Step 4: Select Receipt To Upload'>
                                                <ReceiptSelectionGroup />
                                            </ClaimFormCard>

                                            <ClaimFormCard title='Step 5: Declaration &amp; Submission'>
                                                <DeclarationAndSubmissionGroup isWorking={isWorking} />
                                                {errorMessage && <span className='text-danger ms-2'>- {errorMessage}</span>}
                                            </ClaimFormCard>
                                        </Form>
                                    </FormProvider>
                                )
                            }}
                </MemberSchemesDigestProvider>
            </Col>
        </Row>
    </>
}

export default CalorClaimForm;