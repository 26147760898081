import { createContext } from 'react';

import { SetState } from "../../../../models/UtilityTypes";
import { CompanyTypeData } from "../../types";

export type MyDetailsFormState = 'clean' | 'dirty' | 'submitted';

export type MyDetailsFormValidatedFields = {
    companyNumberValid: boolean,
    mailingPostCodeValid: boolean,
    invoicePostCodeValid: boolean,
    additionalContactsEmailValid: any[]
    additionalContactsPhoneValid: any[]
}

type MyDetailsContextType = {
    formState: MyDetailsFormState,
    setFormState: SetState<MyDetailsFormState>,
    validatedFields: MyDetailsFormValidatedFields,
    setValidatedFields: SetState<MyDetailsFormValidatedFields>,
    validateCompanyNumber: (companyNumber: string, companyType: string) => void,
    validatePostCode: (postCode: string, isMailingAddress: boolean) => void,
    validateEmailAddress: (email: string, index: number) => void,
    validatePhone: (num: string, index: number) => void,
    companyTypes: CompanyTypeData[],
    setCompanyTypes: (ct: CompanyTypeData[]) => void,
    formValid: () => boolean,
    clearValidation: () => void
}

export const defaultValidatedFields: MyDetailsFormValidatedFields = {
    companyNumberValid: true,
    mailingPostCodeValid: true,
    invoicePostCodeValid: true,
    additionalContactsEmailValid: [],
    additionalContactsPhoneValid: []
}

const MyDetailsContext = createContext<MyDetailsContextType>({
    formState: 'clean',
    setFormState: () => {},
    companyTypes: [],
    setValidatedFields: () => {},
    setCompanyTypes: (ct: CompanyTypeData[]) => {},
    validatedFields: defaultValidatedFields,
    validateCompanyNumber: (companyNumber, companyType) => true,
    validatePostCode: (postCode, isMailingAddress) => true,
    validateEmailAddress: (email: string, index: number) => true,
    validatePhone: (num: string, index: number) => true,
    formValid: () => true,
    clearValidation: () => {}
});

export default MyDetailsContext;