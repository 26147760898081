import { useNavigate } from 'react-router';
import { Button } from 'reactstrap';
import ClaimFormCard from '../../../../../components/rewards/calor/claim-form/ClaimFormCard';
import { calorSignUpFormRoute } from '../sign-up/route';

const UnableToMakeClaimMessage = () => {
    const navigate = useNavigate();

    return (
        <ClaimFormCard title='Sorry'>
            <span className='d-block mb-2'>
                You are not able to make a claim at this time; please sign up to the Calor scheme by following the link below.
                Alternatively if you have already signed up for the scheme then please await your account being approved and then return here to submit your claims.
            </span>
            <Button color='primary' onClick={() => navigate(calorSignUpFormRoute.path)}>Sign up</Button>
        </ClaimFormCard>
    )
}

export default UnableToMakeClaimMessage;