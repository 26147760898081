import moment from "moment";
import numeral from 'numeral';
import { CurrencyNumber, DateTimeString } from "../models/BrandedTypes";
import Period from "../models/rewards/Period";

export const formatCurrency = (amount: CurrencyNumber) => {
    return `£${numeral(amount).format('0,0[.]00')}`;
}

export const formatPercentage = (percentage: number) => {
    return `${numeral(percentage).format('0,0[.]0')}%`;
}

export const formatDate = (date: DateTimeString | Date, formatString: string = 'DD/MM/YYYY'): string => {
    return moment(new Date(date)).format(formatString);
}

export const formatDateOnlyISO = (date: Date): DateTimeString => {
    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
    )).toISOString() as DateTimeString;
}

export const formatPeriod = (period: Period): string => {
    return `${period.name} (${formatDate(period.startDate)} to ${formatDate(period.endDate)})`;
}

// Based on the answer to this StackOverflow question: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) {
        return '0 Bytes';
    } else if (bytes === 1) {
        return '1 Byte';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}