import { logoutActions as calorClaimFormLogoutActions } from "../../routes/pages/rewards/calor/claim-form";
import { logoutActions as pageLoadLogoutActions } from "../PageLoad";
import { logoutActions as membershipImpersonationLogoutActions } from "../MembershipImpersonation/MembershipImpersonationContext";

export type LogoutAction = () => void;

export const logoutActions: LogoutAction[] = [
    ...calorClaimFormLogoutActions,
    ...pageLoadLogoutActions,
    ...membershipImpersonationLogoutActions,
]