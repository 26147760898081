import React, { useCallback, useState } from 'react';
import { useIsMounted } from './useIsMounted';
import { useResetableState } from './useResetableState';

const useSafeSetState = <T extends unknown>(setState: React.Dispatch<React.SetStateAction<T>>): React.Dispatch<React.SetStateAction<T>> => {
    const isMounted = useIsMounted();

    return useCallback<React.Dispatch<React.SetStateAction<T>>>(newState => {
        isMounted.current && setState(newState);
    }, [isMounted, setState]);
}

const useSafeResetState = (func: () => void): (() => void) => {
    const isMounted = useIsMounted();

    return useCallback<() => void>(() => {
        isMounted.current && func();
    }, [isMounted, func]);
}

export const useSafeState = <T extends unknown>(initialState: T | (() => T)): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [state, setState] = useState(initialState);

    return [state, useSafeSetState(setState)];
}

export const useSafeResetableState = <T extends unknown>(initialState: T | (() => T)): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
    const [state, setState, resetState] = useResetableState(initialState);

    return [state, useSafeSetState(setState), useSafeResetState(resetState)];
}