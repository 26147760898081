import React, { useCallback, useMemo } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import createPersistedState from "use-persisted-state";
import { useActionableModalState } from "./hooks";
import { ModalActionedCallback, StandardModalProps, ToggleMethod } from "./types";

export type OneTimeModalProps = Omit<StandardModalProps, 'children'> & {
    localStorageKey: string,
    dontShowAgainKey?: string,
    closeButtonText?: string,
    dontShowAgainButtonText?: string,
    children?: React.ReactNode | ((toggle: () => void) => React.ReactNode),
}

const OneTimeModal = (props: OneTimeModalProps) => {
    const {
        modalTitle,
        localStorageKey,
        dontShowAgainKey = `${localStorageKey}_dont-show-again`,
        onDontShowAgain,
        closeButtonText = 'Close',
        dontShowAgainButtonText = "Don't show again",
        buttonJustification = 'center',
        toggle: propsToggle,
        children,
        ...modalProps
    } = props;

    const useShowModal = useMemo(() => createPersistedState<boolean>(localStorageKey), [localStorageKey]);
    const useDontShowAgain = useMemo(() => createPersistedState<boolean>(dontShowAgainKey), [dontShowAgainKey]);

    const [dontShowAgain, setDontShowAgain] = useDontShowAgain(false);
    const [showModal, setShowModal] = useShowModal(!dontShowAgain);

    const innerToggle = useCallback<ToggleMethod>(event => {
        setShowModal(show => !show);
        propsToggle && propsToggle(event);
    }, [setShowModal, propsToggle]);

    const actionedCallback = useCallback<ModalActionedCallback>((_, closeModal) => {
        setDontShowAgain(true);
        closeModal();
    }, [setDontShowAgain]);

    const {
        toggle,
        handleAction,
    } = useActionableModalState({actionedCallback, propsToggle: innerToggle});

    return (
        <Modal isOpen={showModal} toggle={toggle} {...modalProps}>
            <ModalHeader toggle={toggle}>
                <span>{modalTitle}</span>
            </ModalHeader>
            {children &&
                <ModalBody>
                    {typeof(children) === 'function' ? children(toggle) : children}
                </ModalBody>
            }
            <ModalFooter className={`d-flex w-100 justify-content-${buttonJustification}`}>
                <Button
                    id='close'
                    color='primary'
                    onClick={toggle}
                    className='ms-1 me-3'
                >
                    {closeButtonText}
                </Button>
                <Button
                    id='dontShowAgain'
                    className='me-1'
                    color='secondary'
                    onClick={handleAction}
                >
                    {dontShowAgainButtonText}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default OneTimeModal;