import { Controller, useFormContext } from 'react-hook-form';
import { Col, Input, Label, Row } from 'reactstrap';
import { ClaimFormState } from '../../../../models/rewards/calor/ClaimForm';

const validReceiptFileTypes: string[] = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png', 'application/pdf'];

const ReceiptSelectionGroup = (): JSX.Element => {
    const {formState: {errors}} = useFormContext<ClaimFormState>();

    return <>
        <Row>
            <Col md={9} lg={7} xl={5}>
                <Label for='receipt-file'>Please select receipt to upload:</Label>
                <p>Suported file types: JPEG, PNG, PDF, BMP or GIF</p>
                <p><b>Please make sure the receipt you are uploading is in a vertical orientation, with the retailer's details at the top.</b></p>
                <Controller
                    name='receiptFile'
                    render={({field: {ref, value, onChange, ...rest}}) => (
                        <Input
                            type='file'
                            multiple={false}
                            accept={validReceiptFileTypes.join(',')}
                            invalid={errors.receiptFile !== undefined}
                            onChange={event => onChange(event.target.files[0])}
                            {...rest}/>
                    )}
                    rules={{required: true, validate: (file: File) => validReceiptFileTypes.findIndex(type => type === file.type) !== -1}}/>
            </Col>
        </Row>

        {/* The error object type property appears to be incorrectly typed. It is actually a string, but it's typescript type is incompatible with string, thus the casting below. */}
        {errors.receiptFile && (errors.receiptFile.type as unknown as string) === 'required' && <span className='d-block text-danger'>A single receipt image is required</span>}
        {errors.receiptFile && (errors.receiptFile.type as unknown as string) === 'validate' && <span className='d-block text-danger'>Receipt image file type must be one of .bmp, .gif, .pdf, .jpeg or .png</span>}
    </>;
}

export default ReceiptSelectionGroup;