import { Controller, useFormContext } from 'react-hook-form';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { ClaimFormState } from '../../../../models/rewards/calor/ClaimForm';
import HyperDatepicker from '../../../common/Datepicker';

const Today = new Date();

const getCurrentPeriod = () => {
    return Today.getMonth() < 6 ? {startDate: "1st January", endDate: "30th June"} : {startDate: "1st July", endDate: "31st December"}
}

const PurchaseDateGroup = (): JSX.Element => {
    const {formState: {errors}} = useFormContext<ClaimFormState>();
    const {startDate,endDate} = getCurrentPeriod();
    return (
        <FormGroup>
            <Label for='purchase-date' className='mb-0'>Date on your receipt:</Label>
            <p>Your receipts need to be uploaded within the current period: {startDate} - {endDate}. If you upload a receipt outside these dates, it will be rejected and wont count towards your cashback.</p>

            <Row>
                <Col md={8} lg={6} xl={4}>
                    <Controller
                        name='purchaseDate'
                        render={({field: {ref, value, onChange, ...rest}}) => (
                            <HyperDatepicker
                                id='purchase-date'
                                maxDate={Today}
                                placeholderText='00/00/0000'
                                isInvalid={errors.purchaseDate !== undefined}
                                selected={value}
                                onChange={newDate => onChange(newDate)}
                                hideAddon
                                {...rest}/>
                        )}
                        rules={{required: true}}/>
                </Col>
            </Row>

            {errors.purchaseDate && <span className='d-block text-danger'>Purchase date required</span>}
        </FormGroup>
    );
}

export default PurchaseDateGroup;