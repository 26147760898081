import { CompanyTypeOption } from "../types";


const companyNumberRegex = "^((AC|ZC|FC|GE|LP|OC|SE|SA|SZ|SF|GS|SL|SO|SC|ES|NA|NZ|NF|GN|NL|NC|R0|NI|EN|\\d{2}|SG|FE)\\d{5}(\\d|C|R))|((RS|SO)\\d{3}(\\d{3}|\\d{2}[WSRCZF]|\\d(FI|RS|SA|IP|US|EN|AS)|CUS))|((NI|SL)\\d{5}[\\dA])|(OC(([\\dP]{5}[CWERTB])|([\\dP]{4}(OC|CU))))$";
const charityNumberRegex = "^[0-9]{6,8}$";
const postCodeRegex = "^[a-zA-Z0-9]+[ ][a-zA-Z0-9]+";
const phoneRegex = "^0([1-6][0-9]{8,10}|7[0-9]{9})$";


export const invalidCompanyNumberLabel = "Please enter the Company Registration Number or Charity Number in the correct format";
export const invalidMailingPostCodeLabel = "Please enter a valid postcode for the mail address with a space in the middle";
export const invalidInvoicePostCodeLabel = "Please enter a valid postcode for the invoice address with a space in the middle";
export const invalidEmailAddressLabel = "Please enter a valid email address";
export const invalidBusinessPhoneLabel = "Please enter a valid UK telephone number";

export const companyNumberNotNeeded = (companyType: string) => {
    return companyType === CompanyTypeOption.SoleTrader ||  companyType === CompanyTypeOption.BusinessNotRegistered || companyType === CompanyTypeOption.LLP || companyType === CompanyTypeOption.Partnerships
}

export const companyNumberRegexTest = (companyNumber: string, isCharity: boolean) => {
    const regexp = !isCharity ? 
    new RegExp(companyNumberRegex) :
    new RegExp(charityNumberRegex);
    return regexp.test(companyNumber);
}

export const postCodeRegexTest = (postCode: string) => {
    const regexp = new RegExp(postCodeRegex);
    return regexp.test(postCode);
}

export const emailAddressTest = (email: string) => {
    return !!email && email.includes('@'); // simple test to match FluentValidation check in back-end
}

export const phoneNumberTest = (num: string) => {
    const sanitised = num.replace(/ +/g, "").replace("(", "").replace(")", "");
    const regexp = new RegExp(phoneRegex);
    return regexp.test(sanitised);
}


