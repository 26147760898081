import React from 'react';

const withContextFunctionalChildren = <TProps extends React.PropsWithChildren<{}>, T>(
    Component: React.ComponentType<TProps>,
    Context: React.Context<T>,
) => {
    return ({children, ...rest}: Omit<TProps, 'children'> & {children?: React.ReactNode | ((value: T) => React.ReactNode)}) => (
        <Component {...rest as any}>
            {children instanceof Function
                ? <Context.Consumer>{value => children(value)}</Context.Consumer>
                : children}
        </Component>
    );
}

export default withContextFunctionalChildren;