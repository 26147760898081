import classnames from 'classnames';
import React, { useCallback } from 'react';
import { DeepPartial, FormProvider, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DismissableAlert from '../DismissableAlert';
import { useActionableModalState } from './hooks';
import { ErrorCallback, StandardModalProps, ToggleMethod } from './types';

export type FormModalOnSubmitHandler<T> = (data: T, setError: ErrorCallback, closeModal: () => void, completedCallback: () => void) => void | Promise<void>;

export type FormModalProps<T extends Record<string, any>> = Omit<StandardModalProps, 'onSubmit' | 'children'> & {
    onSubmit: FormModalOnSubmitHandler<T>,
    children: ((formContext: UseFormReturn<T>) => React.ReactNode) | React.ReactNode,

    resetOnToggle?: boolean,
    defaultValues?: DeepPartial<T>
    submitButtonText?: string,
    cancelButtonText?: string,
}

const isolateForm = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
}

const FormModal = <T extends Record<string, any>>(props: FormModalProps<T>): JSX.Element  =>{
    const {
        modalTitle,
        onSubmit,
        submitButtonText = 'Submit',
        cancelButtonText = 'Cancel',
        buttonJustification = 'center',
        defaultValues,
        resetOnToggle = true,
        toggle: propsToggle,
        isOpen,
        children,
        ...modalProps
    } = props;

    const formContext = useForm<T>({defaultValues});

    const toggleWithFormReset: ToggleMethod = useCallback(event => {
        if (resetOnToggle) {
            formContext.reset(defaultValues);
        }

        if (propsToggle) {
            propsToggle(event);
        }
    }, [resetOnToggle, propsToggle, formContext, defaultValues]);

    const toggleWithFormSubmit: ToggleMethod = useCallback(event => {
        if (propsToggle) {
            propsToggle(event);
        }
    }, [propsToggle]);

    const {
        dismissableAlertProps,
        hasErrorMessage,
        isWorking,
        errorHandler,
        setWorking,
        setNotWorking,
        toggle,
    } = useActionableModalState({propsToggle: toggleWithFormReset});

    const handleSubmit: SubmitHandler<T> = (data) => {
        setWorking();
        onSubmit(data, errorHandler, toggleWithFormSubmit, setNotWorking);
        if (resetOnToggle) {
            formContext.reset(defaultValues);
        } else {
            formContext.reset(data);
        }
    }
    return (
        <Modal isOpen={isOpen} toggle={toggle} onSubmit={isolateForm} {...modalProps}>
            <Form onSubmit={formContext.handleSubmit(handleSubmit)}>
                <ModalHeader toggle={toggle}>
                    <span>{modalTitle}</span>
                </ModalHeader>
                <ModalBody>
                    {children instanceof Function
                        ? children(formContext)
                        : <FormProvider {...formContext}>{children}</FormProvider>}
                </ModalBody>
                <ModalFooter className='flex-column'>
                    <DismissableAlert {...dismissableAlertProps} />
                    <div className={classnames('d-flex w-100', `justify-content-${buttonJustification}`)}>
                        <Button
                            id='submit'
                            color='primary'
                            type='submit'
                            disabled={hasErrorMessage || isWorking}
                            className='ms-1 me-3'
                        >
                            {submitButtonText} {isWorking && <i className='mdi mdi-spin mdi-loading'/>}
                        </Button>
                        <Button
                            id='cancel'
                            color='secondary'
                            className='me-1'
                            onClick={() => {formContext.reset(); toggle();}}
                        >
                            {cancelButtonText}
                        </Button>
                    </div>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default FormModal;