import { useState, useContext } from 'react';
import { useFormContext } from "react-hook-form";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";

import { CompanyTypeOption, MemberDetailsInfo } from './../types';
import MyDetailsContext from './store/MyDetailsContext';
import { invalidCompanyNumberLabel } from './my-details-validation';

export type CompanyDetailsFormProps = {
    companyTypeId: number;
    companyTypeName: string;
    companyRegistrationOrCharityNumber: string;
}

export const checkShowCompanyNumber = (companyType: string):boolean => {
    return companyType === CompanyTypeOption.PLC ||  companyType === CompanyTypeOption.LimitedCompany || companyType === CompanyTypeOption.Charity;
}

const CompanyDetailsForm = ({companyTypeId, companyTypeName, companyRegistrationOrCharityNumber }: CompanyDetailsFormProps) => {
    const myDetailsContext = useContext(MyDetailsContext);

    const [enteredCompanyType, setEnteredCompanyType] = useState<string>(companyTypeName);
    const [enteredCompanyNumber, setEnteredCompanyNumber] = useState<string>(companyRegistrationOrCharityNumber);
    const [showCompanyNumber, setShowCompanyNumber] = useState<boolean>(checkShowCompanyNumber(companyTypeName));

    const {register} = useFormContext<MemberDetailsInfo>();
    const {ref: companyTypeRef, ...companyTypeRegister} = register(`companyTypeId`, { required: true });
    const {ref: companyNumberRef, ...companyNumberRegister} = register(`companyRegistrationOrCharityNumber`, { required: showCompanyNumber });

    const companyNumberFieldIsInvalid = myDetailsContext.formState === 'dirty' && !myDetailsContext.validatedFields.companyNumberValid;

    const handleCompanyTypeChange = (event) => {
        myDetailsContext.setFormState('dirty');
        myDetailsContext.validateCompanyNumber(enteredCompanyNumber, event.target.selectedOptions[0].text);
        setEnteredCompanyType(event.target.selectedOptions[0].text);
        setShowCompanyNumber(checkShowCompanyNumber(event.target.selectedOptions[0].text));
    }

    const handleCompanyNumberChange = (event) => {
        myDetailsContext.setFormState('dirty');
        myDetailsContext.validateCompanyNumber(event.target.value, enteredCompanyType);
        setEnteredCompanyNumber(event.target.value);
    }

    return <>
        <Row>
            <Col>
                <Label>Company Type:</Label>
            </Col>
            <Col md={9}>
                <FormGroup>
                    <Input
                        name="companyType"
                        type="select"
                        defaultValue={companyTypeId}
                        innerRef={companyTypeRef}
                        {...companyTypeRegister}
                        invalid={companyTypeId <= 0}
                        onChange={handleCompanyTypeChange}               
                    >
                        {   
                            myDetailsContext.companyTypes.map((x) => {
                                return <option key={x.id} value={x.id}>{x.text}</option>
                            })
                        }
                    </Input>
                    <FormFeedback>
                        Please enter the Company type
                    </FormFeedback>
                </FormGroup>
            </Col>
        </Row>
        <Row className={!showCompanyNumber ? "d-none" : ""}>
            <Col>
                <Label>Company Registration Number or Charity Number</Label>
            </Col>
            <Col md={9}>
                <FormGroup>
                    <Input
                        name="companyRegistrationorCharityNumber"
                        defaultValue={companyRegistrationOrCharityNumber}
                        invalid={companyNumberFieldIsInvalid}
                        maxLength={10}
                        innerRef={companyNumberRef}
                        {...companyNumberRegister}
                        onChange={handleCompanyNumberChange}
                        required = {showCompanyNumber}
                    />
                    <FormFeedback>
                        {invalidCompanyNumberLabel}
                    </FormFeedback>
                </FormGroup>
            </Col>
        </Row>
    </>
}

export default CompanyDetailsForm;