import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

export type ClaimFormCardProps = React.PropsWithChildren<{
    title: string
}>

const ClaimFormCard = ({title, children}: ClaimFormCardProps) => {
    return (
        <Card>
            <CardBody className="p-4">
                <CardTitle tag="h4" className='mt-1 mb-3 text-uppercase ncass-green-color fw-bolder'>{title}</CardTitle>
                {children}
            </CardBody>
        </Card>
    )
}

export default ClaimFormCard;