import VatPrice from "../models/VatPrice";

export const defaultVatPercentage = 20;

export const calculateVatPrice = (price: number, includesVat: boolean, vatPercentage: number = defaultVatPercentage): VatPrice => {
    if (vatPercentage > 1) {
        vatPercentage /= 100;
    }

    return (includesVat
        ? {incVat: price, excVat: price / (1 + vatPercentage)}
        : {incVat: price * (1 + vatPercentage), excVat: price}) as VatPrice
}