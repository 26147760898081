import React from 'react';
import { Alert } from 'reactstrap';

export type DismissableAlertType = 'danger' | 'warning' | 'info' | 'success';

export type DismissableAlertProps = {
    alertContent: React.ReactChild,
    alertType: DismissableAlertType,
    onDismissal: () => void,
}

const DismissableAlert = ({alertContent, alertType, onDismissal}: DismissableAlertProps): JSX.Element => {
    if (!alertContent) {
        return null;
    }

    return <Alert toggle={onDismissal} color={alertType}>{alertContent}</Alert>
}

export default DismissableAlert;