import React from 'react';
import { Button } from 'reactstrap';
import ClaimFormCard from '../../../../../components/rewards/calor/claim-form/ClaimFormCard';

export type SuccessMessageProps = {
    claimReference: string,
    handleMakeAnotherClaim: () => void,
};

const SuccessMessage = ({claimReference, handleMakeAnotherClaim}: SuccessMessageProps) => {
    return (
        <ClaimFormCard title='Success'>
            <span className='d-block mb-2'>
                Thank you, your claim request has been submitted with reference number
                <span className='text-primary'>&nbsp;{claimReference}&nbsp;</span>
                and will now be processed. Keep an eye on your inbox for our email with an update on the status of your claim request.
            </span>
            <Button color='primary' onClick={handleMakeAnotherClaim}>Make another claim</Button>
        </ClaimFormCard>
    );
}

export default SuccessMessage;