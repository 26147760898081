import { useState } from 'react';

import { useFormContext } from "react-hook-form";
import { Row, Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import { CompanyTypeOption } from '../../my-account/types';
import { useMembershipInfoContext } from "../../MembershipInfo/MembershipInfoContext";
import { checkShowCompanyNumber } from '../../my-account/my-details/CompanyDetailsForm';
import { invalidCompanyNumberLabel } from '../../my-account/my-details/my-details-validation';
import { companyNumberNotNeeded, companyNumberRegexTest } from '../../my-account/my-details/my-details-validation';
import { MissingCompanyDetailsFormFields } from './MissingCompanyDetailsModal';

export type CompanyDetailsFormProps = {
    companyTypeId: number;
    companyTypeName: string;
    companyRegistrationOrCharityNumber: string;
}


const MissingCompanyDetailsForm = ({ companyTypeId, companyTypeName, companyRegistrationOrCharityNumber }: CompanyDetailsFormProps) => {
    const { companyTypes, setFormState, formState } = useMembershipInfoContext();
    const [enteredCompanyTypeId, setEnteredCompanyTypeId] = useState<number>(companyTypeId);
    const [enteredCompanyTypeName, setEnteredCompanyTypeName] = useState<string>(companyTypeName);
    const [enteredCompanyNumber, setEnteredCompanyNumber] = useState<string>(companyRegistrationOrCharityNumber);
    const [enteredVatRegistered, setEnteredVatRegistered] = useState<"true" | "false" | null>(null);
    const [showCompanyNumber, setShowCompanyNumber] = useState<boolean>(checkShowCompanyNumber(companyTypeName));

    const formContext = useFormContext<MissingCompanyDetailsFormFields>();
    const { register, setValue } = formContext;
    const { ref: companyTypeRef, ...companyTypeRegister } = register(`companyTypeId`, { required: true });
    const { ref: companyNumberRef, ...companyNumberRegister } = register(`companyRegistrationOrCharityNumber`, { required: showCompanyNumber });
    const { ref: vatRegisteredYesRef, ...vatRegisteredYesRegister } = register(`vatRegistered`, { required: true });
    const { ref: vatRegisteredNoRef, ...vatRegisteredNoRegister } = register(`vatRegistered`, { required: true });

    const companyTypeisInvalid = (formState === 'dirty' || formState === 'invalid') && enteredCompanyTypeId <= 0;
    const companyNumberFieldIsInvalid = (formState === 'dirty' || formState === 'invalid') && 
                                        (!companyNumberNotNeeded(enteredCompanyTypeName) && !companyNumberRegexTest(enteredCompanyNumber, enteredCompanyTypeName === 'Charity'));

    const vatRegisteredIsInvalid = (formState === 'dirty' || formState === 'invalid') && (enteredVatRegistered === undefined || enteredVatRegistered === null) ;


    const handleCompanyTypeChange = (event) => {
        setFormState('dirty');
        setEnteredCompanyTypeId(event.target.selectedOptions[0].value);
        setEnteredCompanyTypeName(event.target.selectedOptions[0].text);
        setShowCompanyNumber(checkShowCompanyNumber(event.target.selectedOptions[0].text));
    }

    const handleCompanyNumberChange = (event) => {
        setFormState('dirty');
        setEnteredCompanyNumber(event.target.value);
    }

    const handleVatRegisteredChange = (value: "true" | "false" | null) => {
        setFormState('dirty');
        setEnteredVatRegistered(value);
        // This form doesn't work in IOS/Safari without this and I don't know why
        setValue("vatRegistered", value);
    }

    return <>
                <Row>
                    <Col md={9}>
                        <Label>Company Type:</Label>
                        <FormGroup>
                            <Input
                                name="companyType"
                                type="select"
                                defaultValue={companyTypeId}
                                innerRef={companyTypeRef}
                                {...companyTypeRegister}
                                invalid={companyTypeisInvalid}
                                onChange={handleCompanyTypeChange}
                            >
                                {
                                    companyTypes.filter(ct => ct.text !== CompanyTypeOption.BusinessNotRegistered).map((x) => {
                                        return <option key={x.id} value={x.id}>{x.text}</option>
                                    })
                                }
                            </Input>
                            <FormFeedback>
                                Please enter the Company type
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={!showCompanyNumber ? "d-none" : ""}>
                    <Col md={9}>
                        <FormGroup>
                            <Label>Company Registration Number or Charity Number</Label>
                            <Input
                                name="companyRegistrationorCharityNumber"
                                defaultValue={companyRegistrationOrCharityNumber}
                                invalid={companyNumberFieldIsInvalid}
                                maxLength={10}
                                innerRef={companyNumberRef}
                                {...companyNumberRegister}
                                onChange={handleCompanyNumberChange}
                                required={showCompanyNumber}
                            />
                            <FormFeedback>
                                {invalidCompanyNumberLabel}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <Label>Are you VAT Registered?</Label>
                        <FormGroup check>
                            <Input
                                id="vat-registered-yes"
                                type='radio'
                                value='true'
                                invalid={vatRegisteredIsInvalid}
                                innerRef={vatRegisteredYesRef}
                                {...vatRegisteredYesRegister}
                                checked={enteredVatRegistered === "true"}
                                onChange={() => handleVatRegisteredChange("true")}/>
                            <Label for="vat-registered-yes" check>
                                Yes
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                id="vat-registered-no"
                                type='radio'
                                value='false'
                                invalid={vatRegisteredIsInvalid}
                                innerRef={vatRegisteredNoRef}
                                {...vatRegisteredNoRegister}
                                checked={enteredVatRegistered === "false"}
                                onChange={() => handleVatRegisteredChange("false")}/>
                            <Label for="vat-registered-no" check>
                                No
                            </Label>
                            <FormFeedback>
                                Please answer this question
                            </FormFeedback>
                            {formState === 'invalid' &&
                                <div style={{ display: "block" }} className="invalid-feedback">
                                    Please ensure you have entered all required fields correctly.
                                </div>
                            }
                        </FormGroup>
                    </Col>
                </Row>
            </>
}

export default MissingCompanyDetailsForm;