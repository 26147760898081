import { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';
import useApi from '../../../../hooks/useApi';
import { ClaimFormState } from '../../../../models/rewards/calor/ClaimForm';
import Retailer from '../../../../models/rewards/calor/Retailer';
import EnterRetailerDetailsButton from './EnterRetailerDetailsButton';

export type RetailerDetailsGroupProps = {
    membershipNumber: string,
}

const disableFiltering = (): boolean => true;

const RetailerDetailsGroup = ({membershipNumber}: RetailerDetailsGroupProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);
    const [retailers, setRetailers] = useState<Retailer[]>([]);
    const [query, setQuery] = useState('');
    const {getApi} = useApi();

    const {setValue, formState: {errors}} = useFormContext<ClaimFormState>();

    useEffect(() => {
        if (!query) {
            return;
        }

        let isCurrent = true;
        setIsLoading(true);
        setRetailers([]);
        getApi().then((api) => {
            isCurrent && api.url(`/calor/members/${membershipNumber}/retailers/search`)
                .post({
                    retailerNameOrPostcodeQuery: query
                })
                .json((retailers: Retailer[]) => {
                    isCurrent && setRetailers(retailers);
                    return retailers;
                })
                .catch(err => console.log(err))
                .finally(() => isCurrent && setIsLoading(false))
        })

        return () => {
            isCurrent = false;
        }
    }, [query, membershipNumber, getApi]);

    return (
        <FormGroup className='mb-3 col col-lg-8 p-0'>
            <Label for='select-retailer' className='dark-text-color'>Retailer:</Label>
            <p>Begin typing the first 3 letters of the retailer name or postcode to start the search</p>
            <Controller
                name='retailer'
                render={({field: {ref, value, onChange, ...rest}}) => (
                    <AsyncTypeahead
                        id='select-retailer'
                        options={retailers}
                        labelKey={(option: Retailer) => `${option.name} (${option.postCode})`}
                        multiple={false}
                        minLength={3}
                        isLoading={isLoading}
                        onSearch={setQuery}
                        filterBy={disableFiltering}
                        renderMenuItemChildren={(retailer: Retailer) => <>
                            {retailer.name} - {retailer.address} - {retailer.postCode}
                        </>}
                        isInvalid={errors.retailer !== undefined}
                        selected={value && [value]}
                        onChange={([selected]: Retailer[]) => {
                            const currentRetailer = value as Retailer | undefined;

                            if (selected && selected.vatNumber) {
                                setValue('vatNumber', selected.vatNumber);
                            }
                            else if (currentRetailer && currentRetailer.vatNumber) {
                                setValue('vatNumber', '');
                            }

                            onChange(selected);
                        }}
                        {...rest}/>
                )}
                rules={{required: true}}/>

            {errors.retailer && <span className='d-block text-danger'>Retailer selection required</span>}

            <EnterRetailerDetailsButton membershipNumber={membershipNumber}/>
        </FormGroup>
    );
}

export default RetailerDetailsGroup;