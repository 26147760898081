import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNcassAuth0 } from '../hooks/useNcassAuth0';
import { companyDocumentsRoute } from '../routes/pages/documents/company-documents/route';
import { rewardsHomeRoute } from '../routes/pages/rewards/home/route';
import { removeLocalStorageValue } from './Logout/helpers';
import { useMembershipInfoContext } from "../components/MembershipInfo/MembershipInfoContext";
import OneTimeModal from './modals/OneTimeModal';
import MissingCompanyDetailsModal from './modals/MissingCompanyDetailsModal/MissingCompanyDetailsModal';

const localStorageKey = 'members-control-pannel-first-time-welcome-message';

export const logoutActions = [
    removeLocalStorageValue(localStorageKey),
]

const forceSetDontShow = () => {
    localStorage.setItem(localStorageKey, JSON.stringify(false));
}

const PageLoad = ({children}: React.PropsWithChildren<{}>) => {
    const {user} = useNcassAuth0();
    const { needCompanyDetails } = useMembershipInfoContext();
    const [ showMissingCompanyDetailsModal, setShowMissingCompanyDetailsModal ] = useState<boolean>(needCompanyDetails(user));

    const closeShowMissingCompanyDetailsModal = () => {
        setShowMissingCompanyDetailsModal(false);
    }

    if (user == null || !(user.roles || []).includes("ncassMember")) {
        return children
    }

   return <>
        {children}
        <OneTimeModal
            modalTitle="Welcome to your dashboard!"
            localStorageKey={localStorageKey}
            zIndex={20000}
        >
            {<>
                Since it's your first time here we wanted to recommend 3 key areas to get you started.

                <p/>
                    They are:

                    <ul>
                        <li>
                            <Link to={companyDocumentsRoute.path} onClick={forceSetDontShow}>
                                Creating your Risk Assessments
                            </Link>
                        </li>
                        <li>
                            <Link to={rewardsHomeRoute.path} onClick={forceSetDontShow}>
                                Signing up to rewards
                            </Link>
                        </li>
                        <li>
                            <Link to={companyDocumentsRoute.path} onClick={forceSetDontShow}>
                                Uploading your documents
                            </Link>

                        </li>
                    </ul>

                    Don't worry, there's no rush to complete them straight away. You'll find this on your dashboard until they are done.
                <p/>

                Remember you can contact your account manager if you have any questions.
            </>}
        </OneTimeModal>
        <MissingCompanyDetailsModal showModal={showMissingCompanyDetailsModal} closeModal={closeShowMissingCompanyDetailsModal}/>
    </>
}

export default PageLoad;