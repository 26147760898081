import React, { useCallback, useRef, useState } from 'react';
import { Button, ButtonProps, ModalProps } from "reactstrap";
import ConfirmationModal from '../components/modals/ConfirmationModal';
import FormModal, { FormModalProps } from '../components/modals/FormModal';
import InformationModal from '../components/modals/InformationModal';
import { ToggleMethod } from '../components/modals/types';

export function useDefaultModalToggle<T extends Omit<ModalProps, 'children'>>(ModalToProxy: (props: T) => JSX.Element, toggle?: ToggleMethod) {
    const showModal = useRef(false);
    const [, setStateToForceRender] = useState<{}>({});

    const toggleWithForceRender = useCallback<ToggleMethod>(event => {
        showModal.current = !showModal.current;
        if (toggle) {
            toggle(event);
        }
        setStateToForceRender({});
    }, [toggle])

    const ModalProxy = useRef<React.FC<T>>(props => {
        return <ModalToProxy isOpen={showModal.current} toggle={toggleWithForceRender} {...props} />
    });

    const ButtonProxy = useRef<React.FC<ButtonProps>>(({ onClick, ...otherProps }) => {

        const handleOnClick = (event: React.MouseEvent<any, MouseEvent>) => {
            onClick && onClick(event);
            toggleWithForceRender();
        }

        return <Button onClick={handleOnClick} {...otherProps} />
    })

    return {
        toggle: toggleWithForceRender,
        ModalProxy: ModalProxy.current,
        ToggleModalButton: ButtonProxy.current,
    }
}

export function useConfirmationModal(toggle?: ToggleMethod) {
    const {ModalProxy, ...rest} = useDefaultModalToggle(ConfirmationModal, toggle);

    return {
        ConfirmationModal: ModalProxy,
        ...rest
    };
}

export function useInformationModal(toggle?: ToggleMethod) {
    const {ModalProxy, ...rest} = useDefaultModalToggle(InformationModal, toggle);

    return {
        InformationModal: ModalProxy,
        ...rest
    };
}

export function useFormModal<T extends Record<string, any>>(toggle?: ToggleMethod) {
    const {ModalProxy, ...rest} = useDefaultModalToggle<FormModalProps<T>>((props: FormModalProps<T>) => <FormModal {...props}/>, toggle);

    return {
        FormModal: ModalProxy,
        ...rest,
    }
}