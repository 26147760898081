import { useFormContext } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ClaimFormState } from '../../../../models/rewards/calor/ClaimForm';
import Retailer from '../../../../models/rewards/calor/Retailer';

const VatDetailsGroup = (): JSX.Element => {
    const {register, watch, formState: {errors}} = useFormContext<ClaimFormState>();

    const retailer = watch('retailer') as Retailer;

    const {ref, ...rest} = register('vatNumber', {pattern: /^\d{9}$/});

    return (
        <FormGroup className="col col-md-8 col-lg-4 p-0">
            <Label for='vat-number' className='dark-text-color'>Retailer VAT number:</Label>
            <p>The VAT Number is a 9 digit number</p>
            <Input
                type='text'
                disabled={retailer && Boolean(retailer.vatNumber)}
                invalid={errors.vatNumber !== undefined}
                innerRef={ref}
                {...rest}/>

            <FormFeedback>VAT number must be 9 digits when supplied.</FormFeedback>
        </FormGroup>
    );
}

export default VatDetailsGroup;