import { useFormContext } from 'react-hook-form';
import { Button, FormGroup, Input, Label } from 'reactstrap';

export type DeclarationAndSubmissionGroupProps = {
    isWorking: boolean,
}

const DeclarationAndSubmissionGroup = ({isWorking}: DeclarationAndSubmissionGroupProps): JSX.Element => {
    const {register, formState} = useFormContext();

    const {ref: accurateAndTrueRef, ...accurateAndTrueRest} = register('accurateAndTrueChecked', {required: true});
    const {ref: purchasedForBusinessRef, ...purchasedForBusinessRest} = register('purchasedForBusinessChecked', {required: true});

    return <>
        <Label>I declare that the above claim is:</Label>
        <FormGroup check>
            <Input
                type='checkbox'
                id='accurate-and-true-checkbox'
                className='mb-1'
                invalid={formState.errors.accurateAndTrue !== undefined}
                innerRef={accurateAndTrueRef}
                {...accurateAndTrueRest}/>
            <Label for='accurate-and-true-checkbox' check>
                accurate and true
            </Label>
        </FormGroup>
        <FormGroup check>
            <Input
                type='checkbox'
                id='purchased-for-business-checkbox'
                invalid={formState.errors.purchasedForBusiness !== undefined}
                innerRef={purchasedForBusinessRef}
                {...purchasedForBusinessRest}/>
            <Label for='purchased-for-business-checkbox' check>
                has been purchased for my catering business
            </Label>

            {(formState.errors.accurateAndTrueChecked || formState.errors.purchasedForBusinessChecked) && (
                <span className='text-danger d-block'>You must confirm both of the above to submit your claim</span>
            )}
        </FormGroup>

        <Button color="primary" className='mt-2' disabled={isWorking || (formState.isSubmitted && !formState.isValid)}>
            {isWorking && <i className='mdi mid-spin mdi-loading'/>}
            Submit form
        </Button>
</>
}

export default DeclarationAndSubmissionGroup;