import { ReactChild, useCallback, useRef } from "react";
import { useSafeResetableState, useSafeState } from "../../hooks/useSafe";
import { DismissableAlertProps, DismissableAlertType } from "../DismissableAlert";
import { ErrorCallback, ModalActionedCallback, ToggleMethod } from "./types";

type useActionableModalStateOptions = {
    actionedCallback?: ModalActionedCallback,
    propsToggle?: ToggleMethod,
}

export const useActionableModalState = (options: useActionableModalStateOptions) => {
    const {
        actionedCallback = () => {},
        propsToggle = () => {},
    } = options;

    const [errorMessage, setErrorMessage, resetErrorMessage] = useSafeResetableState<ReactChild>('');
    const [errorLevel, setErrorLevel, resetErrorLevel] = useSafeResetableState<DismissableAlertType>('danger');
    const [onDismissedCallback, setOnDismissedCallback, resetOnDismissedCallback] = useSafeResetableState<(() => void) | undefined>(undefined);

    const [isWorking, setIsWorking] = useSafeState(false);

    const errorHandler = useCallback<ErrorCallback>((message, {level = 'danger', onDismissed} = {}) => {
        setErrorMessage(message);
        setErrorLevel(level);
        setOnDismissedCallback(() => onDismissed);
    }, [setErrorMessage, setErrorLevel, setOnDismissedCallback])

    const resetError = useCallback(() => {
        if (onDismissedCallback !== undefined) {
            onDismissedCallback();
        }

        resetErrorMessage();
        resetErrorLevel();
        resetOnDismissedCallback();
    }, [onDismissedCallback, resetErrorMessage, resetErrorLevel, resetOnDismissedCallback]);

    const toggleCalled = useRef(false);
    toggleCalled.current = false;

    const toggle = useCallback<ToggleMethod>(event => {
        if (!toggleCalled.current) {
            toggleCalled.current = true;
            resetError();
            propsToggle(event);
        }
    }, [propsToggle, resetError])

    const setWorking = useCallback(() => setIsWorking(true), [setIsWorking]);
    const setNotWorking = useCallback(() => setIsWorking(false), [setIsWorking]);

    const handleAction = useCallback(() => {
        setWorking();
        actionedCallback(errorHandler, toggle, setNotWorking);
    }, [actionedCallback, errorHandler, setWorking, setNotWorking, toggle]);

    const dismissableAlertProps: DismissableAlertProps = {
        alertContent: errorMessage,
        alertType: errorLevel,
        onDismissal: resetError,
    }

    return {
        dismissableAlertProps,
        isWorking,
        hasErrorMessage: errorMessage !== '',

        toggle,
        handleAction,
        errorHandler,
        setWorking,
        setNotWorking,
    }
}