import classnames from 'classnames';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { StandardModalProps, ToggleMethod } from './types';

export type InformationModalProps = StandardModalProps & {
    closeButtonText?: string,
    noFooter?: boolean,
}

const InformationModal: React.FC<InformationModalProps> = props => {
    const {
        modalTitle,
        closeButtonText = 'Close',
        buttonJustification = 'center',
        noFooter = false,
        toggle: propToggle,
        isOpen,
        children,
        ...modalProps
    } = props

    const toggle: ToggleMethod = (event) => {
        if (propToggle) {
            propToggle(event);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} {...modalProps}>
            <ModalHeader toggle={toggle}>
                <span>{modalTitle}</span>
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {!noFooter &&
                <ModalFooter className={classnames('d-flex', `justify-content-${buttonJustification}`)}>
                    <Button id='close' color='primary' onClick={() => toggle()}>{closeButtonText}</Button>
                </ModalFooter>
            }
        </Modal>
    )
}

export default InformationModal;