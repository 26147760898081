import React from 'react';
import { calculateVatPrice } from '../../../../../helpers/calculators';
import { formatCurrency } from '../../../../../helpers/formatters';
import { CurrencyNumber } from '../../../../../models/BrandedTypes';
import { AllTotals } from '../../../../../models/rewards/calor/Claim';
import { CylinderPurchaseDetails } from '../../../../../models/rewards/calor/ClaimForm';

export const DisplayTotal = ({total}: {total: CurrencyNumber}) => {
    return <span className='d-block text-center'>{formatCurrency(total)}</span>
}

const parseIntWithNanZero = (numberString: string) => parseInt(numberString) || 0;
const parseFloatWithNanZero = (numberString: string) => parseFloat(numberString) || 0;

export const calculateTotals = (purchaseDetails13kg: CylinderPurchaseDetails, purchaseDetails19kg: CylinderPurchaseDetails, purchaseDetails47kg: CylinderPurchaseDetails): AllTotals => {
    const totalQuantity = 
        + parseIntWithNanZero(purchaseDetails13kg.quantity)
        + parseIntWithNanZero(purchaseDetails19kg.quantity)
        + parseIntWithNanZero(purchaseDetails47kg.quantity);

    const vatPercentage = totalQuantity > 19 ? 20 : 5;

    const totals13kg = calculateVatPrice(
        parseIntWithNanZero(purchaseDetails13kg.quantity) * parseFloatWithNanZero(purchaseDetails13kg.pricePerCylinder),
        purchaseDetails13kg.vatIncluded,
        vatPercentage);
    const totals19kg = calculateVatPrice(
        parseIntWithNanZero(purchaseDetails19kg.quantity) * parseFloatWithNanZero(purchaseDetails19kg.pricePerCylinder),
        purchaseDetails19kg.vatIncluded,
        vatPercentage);
    const totals47kg = calculateVatPrice(
        parseIntWithNanZero(purchaseDetails47kg.quantity) * parseFloatWithNanZero(purchaseDetails47kg.pricePerCylinder),
        purchaseDetails47kg.vatIncluded,
        vatPercentage);

    return {
        quantity: totalQuantity,
        totals13kg,
        totals19kg,
        totals47kg,
        totalOfTotals: calculateVatPrice(totals13kg.incVat + totals19kg.incVat + totals47kg.incVat, true, vatPercentage),
    };
}