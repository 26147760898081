import { useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { WretchError } from "wretch/types";
import { useFormModal } from '../../../../hooks/ModalHooks';
import useApi from '../../../../hooks/useApi';
import Retailer from '../../../../models/rewards/calor/Retailer';
import { ErrorCallback } from '../../../modals/types';

export type EnterRetailerDetailsButtonProps = {
    membershipNumber: string,
}

type RetailerDetailsModel = {
    retailerName: string,
    retailerAddress: string,
    retailerPostCode: string,
}

const EnterRetailerDetailsButton = ({membershipNumber}: EnterRetailerDetailsButtonProps): JSX.Element => {
    const {ToggleModalButton, FormModal} = useFormModal<RetailerDetailsModel>();
    const {getApi} = useApi();
    const {setValue, watch} = useFormContext();

    const handleOnSubmit = useCallback(async (data: RetailerDetailsModel, setError: ErrorCallback, closeModal: () => void, completedCallback: () => void) => {
        const api = await getApi();
        api.url(`/calor/members/${membershipNumber}/retailers/create`)
            .post({
                name: data.retailerName,
                address: data.retailerAddress,
                postCode: data.retailerPostCode,
            })
            .notFound(() => setError('There was an error while creating the retailer.'))
            .internalError(() => setError('There was an error while creating the retailer.'))
            .error(409, () => setError('The retailer details already exist.'))
            .json((retailer: Retailer) => {
                const currentRetailer = watch('retailer') as Retailer;
                if (currentRetailer && currentRetailer.vatNumber) {
                    setValue('vatNumber', '');
                }
                setValue('retailer', retailer);
                closeModal();

                return retailer;
            })
            .catch((err: WretchError) => `Unexpected error with status code ${err.status}`)
            .finally(completedCallback);
    }, [membershipNumber, getApi, setValue, watch]);

    return <>
        <ToggleModalButton color='link' className='ps-0 mt-0 font-italic'><u>Enter retailer details manually</u></ToggleModalButton>
        <FormModal
            modalTitle='Enter retailer details:'
            onSubmit={handleOnSubmit}
            defaultValues={{
                retailerName: '',
                retailerAddress: '',
                retailerPostCode: '',
            }}
        >
            {({register, formState: {errors}}: UseFormReturn<RetailerDetailsModel>) => {
                const {ref: retailerNameRef, ...retailerNameRest} = register('retailerName', {required: true});
                const {ref: retailerAddressRef, ...retailerAddressRest} = register('retailerAddress', {required: true});
                const {ref: retailerPostCodeRef, ...retailerPostCodeRest} = register('retailerPostCode', {required: true});

                return <>
                    <FormGroup>
                        <Label for='name-input'>Retailer name:</Label>
                        <Input
                            type='text'
                            id='name-input'
                            innerRef={retailerNameRef}
                            invalid={errors.retailerName !== undefined}
                            {...retailerNameRest}/>
                        <FormFeedback>Retailer name is required.</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for='address-input'>Retailer address:</Label>
                        <Input
                            type='text'
                            id='address-input'
                            innerRef={retailerAddressRef}
                            invalid={errors.retailerAddress !== undefined}
                            {...retailerAddressRest}/>
                        <FormFeedback>Retailer address is required.</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for='postCode-input'>Retailer post code:</Label>
                        <Input
                            type='text'
                            id='postCode-input'
                            innerRef={retailerPostCodeRef}
                            invalid={errors.retailerPostCode !== undefined}
                            {...retailerPostCodeRest}/>
                        <FormFeedback>Retailer post code is required.</FormFeedback>
                    </FormGroup>
                </>
            }}
        </FormModal>
    </>
}

export default EnterRetailerDetailsButton;