import { useFormContext } from 'react-hook-form';
import { Input, Label } from 'reactstrap';
import { purchaseDetailsPropertyName } from '.';
import { CylinderSize } from '../../../../../models/rewards/calor/Claim';
import { CylinderPurchaseDetails } from '../../../../../models/rewards/calor/ClaimForm';

export type PurchaseDetailsInputProps = {
    size: CylinderSize
}

export const IncludesVatCheckbox = ({size}: PurchaseDetailsInputProps): JSX.Element => {
    const {register} = useFormContext();
    const checkboxId = `includes-vat-${size}`;

    const {ref, ...rest} = register(`${purchaseDetailsPropertyName(size)}.vatIncluded`)

    return <>
        <Input type='checkbox' id={checkboxId} innerRef={ref} {...rest}/>
        <Label for={checkboxId} className='ms-1' check>
            Included
        </Label>
    </>;
}

export const CylinderQuantityInput = ({size}: PurchaseDetailsInputProps): JSX.Element => {
    const {register, formState, trigger} = useFormContext();
    const purchaseDetailsKey = purchaseDetailsPropertyName(size);

    const {ref, onChange, ...rest} = register(`${purchaseDetailsKey}.quantity`, {
        min: 0,
        validate: (price: string) => !isNaN(parseInt(price)),
    });

    return (
        <Input
            type='number'
            min={0}
            max={99}
            onChange={event => {
                formState.isSubmitted && trigger(`${purchaseDetailsKey}.pricePerCylinder`);
                onChange(event);
            }}
            invalid={formState.errors[purchaseDetailsKey] && formState.errors[purchaseDetailsKey]['quantity']}
            innerRef={ref}
            {...rest}/>
    )
}

export const PerCylinderPriceInput = ({size}: PurchaseDetailsInputProps): JSX.Element => {
    const {register, watch, formState: {errors}} = useFormContext();

    const inputId = `per-cylinder-price-${size}`
    const purchaseDetailsKey = purchaseDetailsPropertyName(size);
    const purchaseDetails = watch(purchaseDetailsKey) as CylinderPurchaseDetails;

    const {ref, ...rest} = register(`${purchaseDetailsKey}.pricePerCylinder`, {
        validate: {
            isNumber: (price: string) => !isNaN(parseInt(price)),
            requiredWhenQuantityNonZero: (price: string) => parseInt(price) > 0 || (parseInt(purchaseDetails.quantity) || 0) === 0,
        }
    })

    return (
        <div className='d-inline-flex mb-0'>
            <div className='d-flex align-items-center'>
                <Label for={inputId} className='mb-0 pe-1'>£</Label>
            </div>

            <Input
                type='text'
                id={inputId}
                className='per-cylinder-input'
                size={5}
                invalid={errors[purchaseDetailsKey] && errors[purchaseDetailsKey]['pricePerCylinder'] !== undefined}
                innerRef={ref}
                {...rest}/>
        </div>
    )
}