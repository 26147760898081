import React from 'react';
import MemberSchemesDigest from '../../models/rewards/MemberSchemesDigest';
import ApiResource from '../ApiResource';
import withContextFunctionalChildren from '../higher-order/withContextFunctionalChildren';

export type MemberSchemesDigestContextProps = React.PropsWithChildren<{
    membershipNumber: string
}>;

const MemberSchemesDigestContext = React.createContext<MemberSchemesDigest>(undefined);

const MemberSchemesDigestProvider = ({membershipNumber, children}: MemberSchemesDigestContextProps) => (
    <ApiResource resource={`/offers/members/${membershipNumber}/schemes`}>
        {(schemes: MemberSchemesDigest) => (
            <MemberSchemesDigestContext.Provider value={schemes}>
                {children}
            </MemberSchemesDigestContext.Provider>
        )}
    </ApiResource>
)

export default withContextFunctionalChildren<MemberSchemesDigestContextProps, MemberSchemesDigest>(
    MemberSchemesDigestProvider,
    MemberSchemesDigestContext
);