import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DismissableAlert from '../DismissableAlert';
import { useActionableModalState } from './hooks';
import { ModalActionedCallback, StandardModalProps } from './types';

export type ConfirmationModalProps = StandardModalProps & {
    onConfirm: ModalActionedCallback,

    confirmButtonDisabled?: boolean,
    confirmButtonText?: string,
    cancelButtonText?: string,
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = props => {
    const {
        modalTitle,
        onConfirm,
        confirmButtonDisabled = false,
        confirmButtonText = 'Confirm',
        cancelButtonText = 'Cancel',
        buttonJustification = 'center',
        toggle: propsToggle,
        isOpen,
        children,
        ...modalProps
    } = props;

    const {
        dismissableAlertProps,
        isWorking,
        hasErrorMessage,
        toggle,
        handleAction,
    } = useActionableModalState({actionedCallback: onConfirm, propsToggle});

    return (
        <Modal isOpen={isOpen} toggle={toggle} {...modalProps}>
            <ModalHeader toggle={toggle}>
                <span>{modalTitle}</span>
            </ModalHeader>
            {children &&
                <ModalBody>{children}</ModalBody>
            }
            <ModalFooter className="flex-column">
                <DismissableAlert {...dismissableAlertProps} />
                <div className={`d-flex w-100 justify-content-${buttonJustification}`}>
                    <Button
                        id='confirm'
                        color='primary'
                        onClick={handleAction}
                        disabled={confirmButtonDisabled || hasErrorMessage || isWorking}
                        className='ms-1 me-3'
                    >
                        {confirmButtonText} {isWorking && <i className='mdi mdi-spin mdi-loading'/>}
                    </Button>
                    <Button id='cancel' className='me-1' color='secondary' onClick={toggle}>{cancelButtonText}</Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmationModal;