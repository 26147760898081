import { useFormContext } from 'react-hook-form';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ClaimFormState } from '../../../../models/rewards/calor/ClaimForm';

const ReceiptNumberGroup = (): JSX.Element => {
    const {register} = useFormContext<ClaimFormState>();

    const {ref, ...rest} = register('receiptNumber');

    return (
        <FormGroup className='mb-4'>
            <Label for='receipt-number' className='mb-0'>Receipt number:</Label>
            <p>This may be found on your receipt as transaction/invoice number.</p>
            <Row>
                <Col md={8} lg={6} xl={4}>
                    <Input type='text' innerRef={ref} {...rest}/>
                </Col>
            </Row>
        </FormGroup>
    );
}

export default ReceiptNumberGroup;