import { CurrencyNumber, DateTimeString, GuidString } from "../../BrandedTypes";
import VatPrice from "../../VatPrice";
import Member from '../../Member';
import Retailer from "./Retailer";

export const CylinderSizeArray = ['13kg', '19kg', '47kg'] as const
export type CylinderSize = typeof CylinderSizeArray[number];

export type ClaimStatus = 'Pending' | 'Rejected' | 'Approved';

export type AllTotals = {
    totals13kg: VatPrice,
    totals19kg: VatPrice,
    totals47kg: VatPrice,
    totalOfTotals: VatPrice,
    quantity: number,
}

export type CylinderPuchaseDetailsWithVat = {
    quantity: number,
    pricePerCylinder: CurrencyNumber,
    vatIncluded: boolean,
    cylinderSize: CylinderSize,
    vatPercentage: number,
}

type Claim = {
    id: GuidString,
    memberAccount: Member,
    retailer: Retailer,
    vatNumber: string,
    purchaseDate: DateTimeString,
    receiptNumber: string,
    purchaseDetails: CylinderPuchaseDetailsWithVat[],
    receiptImageLocation: string,
    claimReference: string,
    fraudFlags: string[],
    lastActioned: DateTimeString,
} & ({
    claimStatus: 'Pending',
    calorEmployee: null,
    rejectionReasons: null,
    notes: null,
} | {
    claimStatus: 'Approved',
    calorEmployee: string,
    rejectionReasons: null,
    notes: null,
} | {
    claimStatus: 'Rejected',
    calorEmployee: string
    rejectionReasons: string[],
    notes: string,
})

export default Claim;